@import "custom";
@import "style";
@import "media";
@import "animations";

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: $secondary;
}

::-webkit-scrollbar-thumb {
  border: 2px solid $secondary;
  background-color: $dark;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $primary;
}

section {
  min-height: calc(100vh - #{$navbar-height});
}
