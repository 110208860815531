@import "custom";

.navbar {
  transition: all 0.3s;
  min-height: $navbar-height;
}

.nav-item {
  font-size: large;
}

.navbar-margin {
  margin-top: $navbar-height;
}

.banner {
  margin-top: -#{$navbar-height};
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-content {
  text-align: center;
}

.banner-footer {
  position: absolute;
  bottom: 3em;
}

.btn-top {
  position: fixed;
  bottom: 2em;
  right: 2em;
}

.pointer {
  cursor: pointer;
}

.anchor {
  position: relative;
  top: -#{$navbar-height};
  visibility: hidden;
}

.nav-link.active {
  transition: all 0.3s;
  border-bottom: 1px solid $primary;
}

.footer-icon {
  display: inline;
  text-align: center;
  min-width: 20px;
  margin-left: 0.5em;
  margin-right: 1em;
  color: $primary;
}

.post-card {
  transition: all 0.3s;
}

.post-card:hover {
  box-shadow: $box-shadow;
}

.card-img-top {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.post-img {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 75vh;
  overflow: hidden;
}

.post-content:first-child::first-letter {
  font-size: 4rem;
  color: $primary;
  float: left;
  line-height: 3rem;
  padding-right: 0.75rem;
}

.card-img-top {
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.send-btn {
  width: 100%;
  max-width: 100px;
}
